function openApp(appUrl) {
  // 避免啟動失敗的計時器
  // const timer = window.setTimeout(() => {
    // if (webUrl) {
    //   window.location.href = webUrl;
    // }
  // }, 1000);

  // 判斷瀏覽器來區分啟動方式
  let ifr;
  if (navigator.userAgent.match(/(Safari)/i)) {
    if (navigator.userAgent.match(/(iPhone|iPad|iPod)/i)) {
      // window.clearTimeout(timer);
      window.location.replace(appUrl);
    } else if (navigator.userAgent.match(/Mobile/i)) {
      // window.clearTimeout(timer);
      window.location.replace(appUrl);
    } else {
      ifr = document.createElement('iframe');

      ifr.src = appUrl;
      ifr.style.display = 'none';
      document.body.appendChild(ifr);
    }
  } else if (navigator.userAgent.match(/Chrome/i)) {
    // window.clearTimeout(timer);
    window.location.replace(appUrl);
  } else {
    ifr = document.createElement('iframe');

    ifr.src = appUrl;
    ifr.style.display = 'none';
    document.body.appendChild(ifr);
  }

  // 用 onblur 來確認有沒有多跳一個是否啟動 APP 的確認視窗
  window.onblur = () => {
    // window.clearTimeout(timer);
    if (ifr) {
      ifr.remove();
    }
    window.close();
  };

  window.setTimeout(() => {
    if (ifr) {
      ifr.remove();
    }
  }, 10000);
}

export default openApp;
