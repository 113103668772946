<template>
  <section class="v-reset-password">
    <div class="v-reset-password__content">
      <div>
        <DecotTitle :title="$t('forgotPassword.app.title')" engTitle="RESET PASSWORD" />

        <div class="form-wrap">
          <re-easy-form
            v-model="easyForm"
            :rules="rules"
            :labelConfig="labelConfig"
            ref="reEasyForm"
          >
          </re-easy-form>

          <div class="btn-wrap">
            <re-button round="round" type="border" size="large" @click="submitEasyForm">{{
              $t("confirm")
            }}</re-button>
          </div>

          <div class="account-exist">
            <div class="account-exist">
              <p class="account-exist__text">{{ $t("hasAccount") }}</p>
              <router-link :to="{ name: 'Login' }" class="account-exist__to-login">
                <span class="account-exist__to-login__link">{{ $t("login") }}</span>
                <img class="account-exist__to-login__icon" src="@/assets/icons/arrow-right.svg" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ReButton from '@/components/form/ReButton.vue';
import ReEasyForm from '@/components/form/ReEasyForm.vue';
import DecotTitle from '@/components/global/DecoTitle.vue';
import { resetPasswordRule } from '@/config/easyFormRule';
import { easyFormLabelConfig } from '@/config/easyFormLabel';
import { resetPasswordFromForgetPassword } from '@/lib/cakeHttp';
import dayjs from 'dayjs';
import openApp from '@/utils/openApp';

export default {
  name: 'ResetPassword',
  components: {
    ReEasyForm,
    ReButton,
    DecotTitle
  },
  data() {
    return {
      rememberMe: false,
      radioOptions: [
        {
          label: this.$t('rememberMe'),
          value: true
        }
      ],
      easyForm: [
        {
          label: this.$t('newPassword'),
          prop: 'password',
          value: '',
          type: 'password',
          comp: 'input',
          placeholder: this.$t('passwordPlaceholder')
        },
        {
          label: this.$t('comfirmNewPassword'),
          prop: 'confirmPassword',
          value: '',
          type: 'password',
          comp: 'input',
          placeholder: this.$t('passwordPlaceholder')
        }
      ],
      resetPasswordRule,
      labelConfig: easyFormLabelConfig
    };
  },
  computed: {
    ...mapGetters(['getLanguage']),
    filterData() {
      return this.easyForm.reduce((obj, item) => {
        obj[item.prop] = item.value;

        return obj;
      }, {});
    },
    rules() {
      return resetPasswordRule(this.filterData);
    }
  },
  methods: {
    ...mapMutations(['setToken', 'toggleShowMsg', 'setMsgInfo']),
    async submitEasyForm() {
      const checkForm = await this.$refs.reEasyForm.validateForm();
      if (checkForm.status) {
        this.resetPassword();
      }
    },
    resetPassword() {
      const { password } = this.filterData;
      const { token, type } = this.$route.query;
      // if (token) {
      //   this.setToken(token);
      // }

      resetPasswordFromForgetPassword(
        {
          password,
          token
        },
        () => {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: true,
            desc: this.$t('modifySuccess'),
            callback: () => {
              if (type === 'web') {
                this.$router.push({ name: 'Login' });
              } else {
                openApp('nuwacare://login');

                // 若手機沒裝 app 回登入頁
                setTimeout(() => {
                  this.$router.push({ name: 'Login' });
                }, 1000);
              }
            },
            triggerCallbackOnOverlay: true,
            triggerCallbackOnConfirmBtn: true
          });
        },
        (err) => {
          this.toggleShowMsg(true);
          this.setMsgInfo({
            succeed: false,
            desc: err
          });
        }
      );
    },
    redirectToHome() {
      this.toggleShowMsg(true);
      this.setMsgInfo({
        succeed: false,
        desc: this.$t('redirectToHome'),
        callback: () => {
          const { type } = this.$route.query;

          if (type === 'web') {
            this.$router.push({ name: 'Login' });
          } else {
            openApp('nuwacare://home');

            this.$router.push({ name: 'Login' });
          }
        },
        triggerCallbackOnOverlay: true,
        triggerCallbackOnConfirmBtn: true
      });
    },
    checkQuery() {
      const qs = this.$route.query;
      if (!qs.token || !qs.exp_time) {
        this.redirectToHome();
      }

      const expireTimestamp = Number(qs.exp_time) * 1000;
      const expiredTime = dayjs(expireTimestamp);
      const curr = dayjs(Date.now());
      const result = curr.diff(expiredTime, 's');

      if (result > 0) {
        this.redirectToHome();
      }
    }
  },

  mounted() {
    this.checkQuery();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/color.scss";
@import "@/assets/scss/other/mixin.scss";

.v-reset-password {
  height: 100vh;
  margin-top: -70px;
  @include flex(center, center);
  background-color: #f8f8f8;
  position: relative;

  &::before {
    content: "";
    @include position(tr, 0, 0);
    width: 40%;
    height: 40%;
    background-image: url("~@/assets/img/member/pattern_1.svg");
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    pointer-events: none;
  }
  &::after {
    content: "";
    @include position(bl, 0, 0);
    width: 40%;
    height: 40%;
    background-image: url("~@/assets/img/member/pattern_2.svg");
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    pointer-events: none;
  }
}

.form-wrap {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.btn-wrap {
  margin-top: 50px;
}

.account-exist {
  @include font-style($c-grey1, 14);
  // @include inline-flex(center);
  margin-top: 20px;
  text-align: center;

  &__text {
    display: inline-block;
    @include font-style($c-grey1, 14);
  }

  &__to-login {
    @include inline-flex(center, center);
    @include font-size(14);

    &__icon {
      width: 15px;
      color: $c-link;
    }

    &__link {
      color: $c-link;
    }
  }
}

@media screen and (max-width: 768px) {
  .form-wrap {
    width: 90%;
  }
}
</style>
